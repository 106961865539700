.accordion {
  $accordion: &;
  text-align: left;

  & &__list,
  & &__item {
    list-style: none;
    margin: 0;
    padding: 0;

    &:before {
      display: none;
    }
  }

  &__section {
    margin-bottom: 4rem;
  }

  & &__section-heading {
    color: var(--color-primary-secondary);
    margin: 0 0 0.5rem;
    font-family: Gilroy, sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.7;
  }

  &__item-heading-icon {
    margin: auto;
    position: absolute;
    top: 0;
    color: var(--color-primary-secondary);
    right: 1.4rem;
    bottom: 0;
    transition: transform 0.5s ease;
  }

  & &__item {
    border-bottom: 0.1rem solid var(--color-stroke-1);

    &_active {
      #{$accordion}__item-heading {
        color: var(--bg-color-primary-blue);
      }

      #{$accordion}__item-heading-icon {
        margin: auto;
        position: absolute;
        top: 0;
        color: var(--bg-color-primary-blue);
        right: 1.4rem;
        bottom: 0;
        transform: rotate(-180deg);
      }

      #{$accordion}__item-content {
        grid-template-rows: 1fr;
        padding-bottom: 1.3rem;
      }
    }
  }

  & &__item-heading {
    cursor: pointer;
    margin: 0;
    padding: 1.3rem 4rem 1.3rem 0;
    color: var(--text-color-primary-black);
    font-family: Gilroy, sans-serif;
    font-size: 2rem;
    line-height: 1.7;
    font-weight: 400;
    position: relative;
  }

  &__item-content {
    display: grid;
    overflow: hidden;
    position: relative;
    font-size: 1.8rem;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.5s ease, padding-bottom 0.5s ease;

    img {
      max-width: 99%;
    }

    h4 {
      font-weight: 600;
    }

    ul,
    ol > li,
    ul > li,
    p {
      margin: 0;
    }

    ul {
      margin-bottom: 3px;

      > li {
        padding-left: 2.5rem;
        position: relative;

        &:before {
          content: '';
          background: var(--bg-color-primary-black);
          border-radius: 50%;
          width: 0.5rem;
          height: 0.5rem;
          position: absolute;
          left: 1rem;
          top: 1.35rem;
        }
      }
    }

    ol {
      margin-bottom: 3px;
      counter-reset: ol;

      > li {
        counter-increment: ol;
        position: relative;
        padding-left: 2.5rem;

        &:before {
          content: counter(ol) '. ';
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
