@import '../../styles/media-queries';

:export {
  [class*='back-button'] {
    display: block;
  }
}

.network {
  $network: &;

  width: 100%;
  padding: 2.9rem 1.8rem 7.3rem;
  color: var(--text-color-primary-black);
  font-family: Gilroy, sans-serif;
  font-size: 2rem;
  line-height: 1.7;
  box-sizing: border-box;

  @include for-screen-up(640) {
    padding: 8.2rem 2rem;
  }

  @include for-screen-up(960) {
    padding: 8.2rem 8rem 9.3rem;
  }

  @include for-screen-up(1024) {
    display: flex;
    flex-direction: row;
    gap: 4.1rem;
    padding: 8.2rem 2.9rem;
  }

  @include for-screen-up(1280) {
    padding: 8.2rem 7rem 12.3rem;
    gap: 8rem;
  }

  @include for-screen-up(1366) {
    max-width: 114.1rem;
    margin: auto;
    padding: 9.4rem 0;
  }

  @include for-screen-up(2560) {
    padding: 9.4rem 0 15.3rem;
  }

  &__side {
    display: none;

    @include for-screen-up(1024) {
      display: block;
      min-width: 26rem;
      max-width: 26rem;
    }
  }

  p {
    margin: 0 0 4rem;
    font-size: 2rem;

    & + ul,
    & + ol {
      margin-top: -4rem;
    }
  }

  h3 {
    clear: left;
    margin: 0 0 1.5rem;
    font-family: Lack, sans-serif;
    font-size: 4rem;
    line-height: 1.36;
    font-weight: normal;
    position: relative;

    &:not(:first-child) {
      margin-top: 10rem;
    }

    @include for-screen-up(640) {
      font-size: 4.5rem;
    }
  }

  &__h3-anchor {
    position: absolute;
    top: -10rem;
  }

  h5,
  h4 {
    clear: left;
    margin: 0 0 1.5rem;
    font-family: Lack, sans-serif;
    font-size: 2.1rem;
    line-height: 1.36;
    font-weight: normal;
  }

  h5 {
    font-size: 1.9rem;
  }

  div {
    $div: &;
    &:has(> img),
    &:has(> figure) {
      width: 100%;
      margin-bottom: 4rem;
      display: flex;
      flex-direction: column;
      gap: 3.9rem;
      flex-wrap: wrap;
      justify-content: center;

      @include for-screen-up(480) {
        flex-direction: row;
        gap: 2rem;
      }

      & > figure,
      & > img {
        max-width: 100%;

        img {
          max-width: 100%;
        }
      }

      &:has(img:first-child:nth-last-child(1)[width^='40']),
      &:has(img:first-child:nth-last-child(1)[width^='3']),
      &:has(img:first-child:nth-last-child(1)[width^='2']),
      &:has(img:first-child:nth-last-child(1)[width^='19']),
      &:has(img:first-child:nth-last-child(1)[width^='16']) {
        width: auto;

        @include for-screen-up(640) {
          clear: left;
          float: left;
          margin-right: 2rem;
        }

        & > figure,
        & > img {
          display: block;
          margin: auto;
          max-width: 100%;
        }
      }

      & > figure:first-child:nth-last-child(2),
      & > img:first-child:nth-last-child(2) {
        &,
        & ~ img,
        & ~ figure {
          @include for-screen-up(480) {
            max-width: calc(50% - 1rem);
          }
        }
      }

      & > figure:first-child:nth-last-child(3),
      & > img:first-child:nth-last-child(3) {
        &,
        & ~ img,
        & ~ figure {
          @include for-screen-up(480) {
            max-width: calc(33.333% - 1.333rem);
          }
        }
      }

      #{$div}-figure {
        & > img {
          max-width: 100%;
          width: auto;

          @include for-screen-up(640) {
            width: 100%;
          }
        }
      }
    }
  }

  &__image {
    margin-bottom: 4rem;

    &_floating {
      @include for-screen-up(640) {
        clear: left;
        float: left;
        margin-right: 2rem;
      }
    }

    img {
      display: block;
      margin: auto;
      max-width: 100%;
    }

    & + & {
      margin-top: -1.5rem;
    }
  }

  a {
    color: var(--text-color-primary-blue);
  }

  ol {
    padding: 0;
    margin: 0 0 5rem;
    counter-reset: ol;

    p {
      margin-bottom: 0.9rem;

      & + ul,
      & + ol {
        margin-top: -0.9rem;
      }
    }

    li {
      margin: 0;
      padding: 0;
      counter-increment: ol;
      font-family: Gilroy, sans-serif;
      font-size: 2rem;
      line-height: 1.7;
      display: flex;

      &:before {
        content: counter(ol) '.';
        display: block;
        min-width: 2.8rem;
        text-align: center;
      }
    }
  }

  ul {
    padding: 0;
    margin: 0 0 5rem;
    counter-reset: ol;

    p {
      margin-bottom: 0.9rem;

      & + ol,
      & + ul {
        margin-top: -0.9rem;
      }
    }

    li {
      font-family: Gilroy, sans-serif;
      font-size: 2rem;
      line-height: 1.7;
      display: flex;
      gap: 1rem;
      align-items: flex-start;
      margin: 0;
      padding: 0;

      &:before {
        content: '';
        display: block;
        background-color: var(--bg-color-primary-black);
        min-width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        margin: 1.4rem 0 0 1rem;
      }
    }
  }

  &__section {
    position: relative;
    z-index: 1;

    &-anchor {
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      z-index: -1;
    }
  }
}
