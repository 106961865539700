@import '../../styles/media-queries';

.fourOhFour {
  display: flex;
  min-height: inherit;
  align-items: center;
  flex-direction: column;

  &__content {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  &__summary {
    max-width: 70rem;
    margin: 4rem auto;
    padding: 0 2.6rem;

    @include for-screen-up(768) {
      padding: 0 7rem;
    }
  }

  &__heading {
    margin: 0 0 1.7rem;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 5.1rem;
    font-weight: normal;
    letter-spacing: 2%;
    line-height: 1.26;

    @include for-screen-up(1024) {
      font-size: 4.5rem;
    }
  }

  &__about {
    margin-bottom: 1.7rem;
    font-family: Lack, sans-serif;
    font-size: 2.9rem;

    line-height: 1.25;
    letter-spacing: 2%;

    @include for-screen-up(1024) {
      font-size: 3.2rem;
    }
  }

  &__back {
    font-size: 1.5rem;
    line-height: 1.14;
    letter-spacing: 2%;
    font-family: Helvetica, Arial, sans-serif;

    @include for-screen-up(768) {
      font-size: 1.7rem;
    }

    a {
      color: #317af6;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
