@import '../../styles/media-queries';

.partner-program {
  &__about {
    margin-bottom: 5.3rem;

    @include for-screen-up(640) {
      margin-bottom: 9.4rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 2.5rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 3.5rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 5rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 6rem;
    }
  }

  &__dependence-awards {
    margin: -8.1rem 0 5.5rem;

    @include for-screen-up(640) {
      margin: -12.2rem 0 3.4rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 9.4rem;
    }

    @include for-screen-up(1024) {
      margin: -13rem 0 9.4rem;
    }

    @include for-screen-up(1280) {
      margin-bottom: 11rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 13.5rem;
    }

    @include for-screen-up(1920) {
      margin-bottom: 15.3rem;
    }
  }

  &__plots {
    margin-bottom: 4.9rem;

    @include for-screen-up(640) {
      margin-bottom: 3.1rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 12.8rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 14.2rem;
    }
  }

  &__promocodes {
    margin-bottom: 5.7rem;

    @include for-screen-up(640) {
      margin-bottom: 2.8rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 6.2rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 7.9rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 9.1rem;
    }

    @include for-screen-up(2560) {
      margin-bottom: 23.2rem;
    }
  }

  &__participant {
  }

  &__awards {
    margin: -8.1rem 0 0;

    @include for-screen-up(640) {
      margin: -8.1rem 0 3.9rem;
    }

    @include for-screen-up(960) {
      margin: -12.2rem 0 3.9rem;
    }

    @include for-screen-up(1024) {
      margin-top: -13rem;
    }

    @include for-screen-up(1280) {
      margin: -13rem 0 11rem;
    }
  }

  &__security {
  }

  &__reward-calculator {
    margin-bottom: 100px;
  }
}
