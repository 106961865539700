.side-nav {
  $nav: &;
  position: sticky;
  top: 18.2rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 0.9rem;

  &__link {
    #{$nav} & {
      font-family: Gilroy, sans-serif;
      font-size: 1.6rem;
      line-height: 1.7;
      border-radius: 1.2rem;
      display: block;
      color: var(--text-color-primary-black);
      padding: 0.8rem 1.5rem;
      text-decoration: none;

      &:hover {
        & ~ #{$nav}__bg {
          background-color: var(--filter-blue-hover);
        }

        &:active {
          font-weight: 600;

          & ~ #{$nav}__bg {
            background-color: var(--filter-blue-active);
          }
        }
      }
    }
  }

  &__link_active {
    #{$nav} & {
    }
  }

  &__link_active {
    #{$nav} & {
      color: var(--text-color-primary-white);
      font-weight: 600;
      background-color: var(--bg-color-primary-blue);

      &:hover {
        background-color: var(--bg-color-primary-blue-hover);
      }
    }
  }

  &__bg {
    position: absolute;
    right: 0;
    left: 0;
    border-radius: 1.2rem;
    z-index: -1;
    transition: all 0.3s ease;
  }
}
